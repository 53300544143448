import { useState, useEffect, useRef } from "react";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import {
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
  IconButton,
  useTheme,
  Grid,
  FormControlLabel,
  FormControl,
  Switch,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Header from "../../components/Header";
import { tokens } from "../../theme";
import Cookies from "js-cookie";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import "../../CalendarStyles.css";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PersonIcon from '@mui/icons-material/Person';

import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ACCESS_CATEGORIES from "../../constants/AccessCatagories"
import checkWriteAccess from "../../util/CheckWriteAccess";
import EventDetailsDialog from "../dashboard/EventDetailsDialog";
import ConfirmationDialog from "../../generic/ConfirmationDialog";
import EventDialog from "./eventDialog";

import {formatDateToISO,formatEndDateToISO, formatTime, getCurrentTime, executeDeleteEvent, saveEditedEvent, handleError1, addEventsToCalendar, renderEventContent, adjustEndDateForDisplay} from './calendarUtil';




const Calendar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { projectId } = useParams();
  const navigate = useNavigate();
  const { clientUserId } = useParams();
  const [project, setProject] = useState(null);

  const API_URL = process.env.REACT_APP_API_URL;

  // ref to store the calendar instance
  const calendarRef = useRef(null);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [selectedDate, setSelectedDate] = useState({
    startStr: "",
    endStr: "",
  });

  const [isEventDialogOpen, setIsEventDialogOpen] = useState(false);
  const [newEventTitle, setNewEventTitle] = useState("");

  const [newEventStartTime, setNewEventStartTime] = useState("");
  const [newEventEndTime, setNewEventEndTime] = useState("");

  const [showTimeInputs, setShowTimeInputs] = useState(false);

  const [isCalendarInitialized, setIsCalendarInitialized] = useState(false);

  const [startTime, setStartTime] = useState(getCurrentTime());
  const [endTime, setEndTime] = useState(getCurrentTime(1));

  const [selectedEvent, setSelectedEvent] = useState(null);

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] = useState(false); // State for confirmation dialog (used for deleting event)
  const [eventToDelete, setEventToDelete] = useState(null); // State to store event to delete
  const [eventToEdit, setEventToEdit] = useState(null); // State to store event to delete
  const [isEdit, setIsEdit] = useState(null);
  const [shareWithClient, setShareWithClient] = useState(false);
  const [newDescription, setNewDescription] = useState("");
  const [eventBackgroundColor, setEventBackgroundColor] = useState("#3788d8"); 


  useEffect(() => {
    if (isCalendarInitialized) {
      fetchEvents();
    }
  }, [isCalendarInitialized]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);


  useEffect(() => {
    fetchEvents();
  }, []);

  const fetchEvents = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/calander-event/get-event-for-projectId/${projectId}`,
        { withCredentials: true }
      );
      const eventDTOs = response.data;
      addEventsToCalendar(calendarRef, eventDTOs);

    } catch (error) {
      console.error("Error fetching events:", error);
      if (error.response) {
        if (error.response.status === 403){
          alert("You are not authorized to view calander events. Please contact Admin for help.");
        }
      }
      // Handle error appropriately
    }
  };

  // Fetch projec tdata
  const fetchProjectData = async () => {
    try {
      const response = await fetch(
        `${API_URL}/project/get-single-project-for-client?projectId=${projectId}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            //'X-XSRF-TOKEN': cookie
          },
        }
      );

      const data = await response.json();
      setProject(data);
    } catch (err) {
      console.error(`Error fetching data for userId ${projectId}:`, err);
    }
  };

  useEffect(() => {
    fetchProjectData();
  }, [projectId]);


  const handleDateClick = async (selected) => {
    if(! await checkWriteAccess(ACCESS_CATEGORIES.CALLENDER)){
      alert("You are not authorized to create Calander Events. Please contact Admin for help.");
      return;
    }

    setSelectedDate(selected);
    setNewEventStartTime(selected.startStr);
    setNewEventEndTime(adjustEndDateForDisplay(selected.startStr, selected.endStr, selected.allDay));
    setShowTimeInputs(!selected.allDay);
    setIsEventDialogOpen(true);
  };

  const handleEventSubmit = () => {

    if(isEdit){
      //Editing existing event
      const isAllDay = !showTimeInputs;

      saveEditedEvent(
        selectedEvent,
        {
            eventId: eventToEdit.eventId, 
            title: newEventTitle,
            start: isAllDay ? formatDateToISO(newEventStartTime) : `${newEventStartTime}T${startTime}:00Z`,
            end: isAllDay ? formatEndDateToISO(newEventEndTime) : `${newEventEndTime}T${endTime}:00Z`,
            allDay: isAllDay,
            description: newDescription,
            sharedWithClient: shareWithClient,
            colourCode: eventBackgroundColor,
            employeeId: eventToEdit.employeeId,
            projectId: eventToEdit.projectId,
            businessId: eventToEdit.businessId,
        },
        calendarRef,
        setIsEventDialogOpen,
        setEventToEdit,
        handleError1
    );
      
    } else {
      // creating new event
      handleEventCreation();
    }


  };

  const handleEventCreation = async () => {
    const calendarApi = selectedDate.view.calendar;
    calendarApi.unselect();

    let event;

    if (newEventTitle) {
      const isAllDay = !showTimeInputs;



      const event = {
        title: newEventTitle,
        start: isAllDay
          ? formatDateToISO(selectedDate.startStr)
          : `${newEventStartTime}T${startTime}:00Z`,
        end: isAllDay
          ? formatDateToISO(selectedDate.endStr)
          : `${newEventEndTime}T${endTime}:00Z`,
        allDay: isAllDay,
        employeeId: Cookies.get("userId"),
        projectId: projectId,
        businessId: Cookies.get("businessId"),
        sharedWithClient: shareWithClient,
        colourCode: eventBackgroundColor,
        description: newDescription,
      };

      try {
        // Post the form data to the backend to create a new document
        const response = await axios.post(`${API_URL}/calander-event/create`, event, {
          withCredentials: true,
          withXSRFToken: true,
          headers: {
            "Content-Type": "application/json",
          },
        });


      calendarApi.addEvent({
        id: response.data.eventId,
        title: newEventTitle,
        start: event.start,
        end: event.end,
        allDay: event.allDay,
        backgroundColor: eventBackgroundColor,
        icon: event.sharedWithClient ? <PersonIcon /> : "",
        extendedProps: {
          shareWithClient: shareWithClient,
          description: newDescription,
          projectId: event.projectId,
          employeeId: event.employeeId,
          businessId: event.businessId,
        },
      });
      } catch (error) {
        console.error("Error creating event:", error);
        if (error.response) {
          if (error.response.status === 403){
            alert("You are not authorized to create calander events. Please contact Admin for help.");
          }
        }
      }
    }

    setIsEventDialogOpen(false);
    setNewEventTitle("");
    setNewEventStartTime("");
    setNewEventEndTime("");
    setStartTime(getCurrentTime());
    setEndTime(getCurrentTime(1));
    setShowTimeInputs(false);
    setShareWithClient(false);
    setNewDescription("");
    setEventBackgroundColor("#3788d8")
  };

  const clearEventDialogUtil = () => {
    setIsEventDialogOpen(false);
    setNewEventTitle("");
    setNewEventStartTime("");
    setNewEventEndTime("");
    setStartTime(getCurrentTime());
    setEndTime(getCurrentTime(1));
    setShowTimeInputs(false);
    setShareWithClient(false);
    setNewDescription("");
    setEventBackgroundColor("#3788d8")
  };

  const handleEventClick = (clickInfo) => {
    setSelectedEvent({
      title: clickInfo.event.title,
      start: clickInfo.event.startStr,
      end: clickInfo.event.endStr,
      allDay: clickInfo.event.allDay,
      projectId: clickInfo.event.extendedProps.projectId,
      eventId: clickInfo.event.id,
      shareWithClient:clickInfo.event.extendedProps.shareWithClient,
      colourCode: clickInfo.event.backgroundColor,
      description: clickInfo.event.extendedProps.description,
      employeeId: clickInfo.event.extendedProps.employeeId,
      businessId: clickInfo.event.extendedProps.businessId,
    });
  }

  const handleClosEventSummaryDialog = () => {
    setSelectedEvent(null);
  };

  const handleEditEvent = async (selected) => {
    if(! await checkWriteAccess(ACCESS_CATEGORIES.CALLENDER)){
      alert("You are not authorized to edit Calander Events. Please contact Admin for help.");
      return;
    }

    setIsEdit(true);
    setSelectedDate({
      startStr: selected.start,
      endStr: selected.end
    });

    // this is required to go from date-time to just the date
    const startDate = new Date(selected.start);
    setNewEventStartTime(startDate.toISOString().split("T")[0]);
    setNewEventEndTime(adjustEndDateForDisplay(selected.start, selected.end, selected.allDay));

    if(!selected.allDay){
      setEndTime(formatTime(selected.end));
      setStartTime(formatTime(selected.start));
      setShowTimeInputs(!selected.allDay);
    }
    
    setEventToEdit(selected);
    handleClosEventSummaryDialog();
    setNewEventTitle(selected.title);
    setShareWithClient(selected.shareWithClient);
    setNewDescription(selected.description);
    setEventBackgroundColor(selected.colourCode);
    setIsEventDialogOpen(true);
  };

  // Trigger the confirmation dialog when delete action is requested
  const handleDeleteEventRequest = (selected) => {
    setEventToDelete(selected);
    setIsConfirmationDialogOpen(true); // Open the confirmation dialog
  };

  const deleteEvent = () => {
    setIsConfirmationDialogOpen(false);
    executeDeleteEvent(eventToDelete, calendarRef, setSelectedEvent, handleError1);

  }

  const navigateTo = (path) => () => {
    navigate(path);
  };

  if (!project) {
    return <div>Loading...</div>;
  }

  return (
    <Box>
      <Box
        display="flex"
        justifyContent="flex-start"
        alignItems="center"
        sx={{ width: "100%", mb: "5px", zIndex: 100, position: "relative" }}
      >
        <Box sx={{ ml: "20px", display: "flex", alignItems: "center" }}>
          {/* Clickable navigation links */}
          <Typography
            variant="h6"
            component="span"
            sx={{ cursor: "pointer" }}
            onClick={navigateTo(`/users`)}
          >
            Clients
          </Typography>
          <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
          {/* Arrow icon */}
          <Typography
            variant="h6"
            component="span"
            sx={{ cursor: "pointer" }}
            onClick={navigateTo(`/users/${clientUserId}`)}
          >
            Projects
          </Typography>
          <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
          {/* Arrow icon */}
          <Typography
            variant="h6"
            component="span"
            sx={{ cursor: "pointer" }}
            onClick={navigateTo(`/users/${clientUserId}/${projectId}`)}
          >
            {project.address}
          </Typography>
          <ArrowForwardIosIcon sx={{ fontSize: "small", mx: 1 }} />{" "}
          <Typography variant="h6" component="span" fontWeight="bold">
            Calendar
          </Typography>
        </Box>
      </Box>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>
          {/* CALENDAR */}
          <Box flex="1 1 100%" m="20px">
            <FullCalendar
              ref={calendarRef}
              timeZone="utc"
              height="75vh"
              // timeZone="local"
              plugins={[
                dayGridPlugin,
                timeGridPlugin,
                interactionPlugin,
                listPlugin,
              ]}
              headerToolbar={{
                left: "prev,next today",
                center: "title",
                right: "dayGridMonth,listMonth",
              }}
              initialView="dayGridMonth"
              editable={false}
              selectable={true}
              selectMirror={true}
              datesSet={() => setIsCalendarInitialized(true)} // Set calendar initialization state
              eventContent={renderEventContent}
              dayMaxEvents={true}
              select={handleDateClick}
              eventClick={handleEventClick}
              longPressDelay={20} // lower value for quicker response
              eventLongPressDelay={20}
            />
          </Box>
        </Grid>
      </Grid>

      {/* Dialog for editing/creating event*/}
      <EventDialog
        isOpen={isEventDialogOpen}
        handleClose={clearEventDialogUtil}
        handleSubmit={handleEventSubmit}
        eventTitle={newEventTitle}
        setEventTitle={setNewEventTitle}
        eventStartTime={newEventStartTime}
        setEventStartTime={setNewEventStartTime}
        eventEndTime={newEventEndTime}
        setEventEndTime={setNewEventEndTime}
        showTimeInputs={showTimeInputs}
        setShowTimeInputs={setShowTimeInputs}
        shareWithClient={shareWithClient}
        setShareWithClient={setShareWithClient}
        eventDescription={newDescription}
        setEventDescription={setNewDescription}
        eventBackgroundColor={eventBackgroundColor}
        setEventBackgroundColor={setEventBackgroundColor}
        startTime={startTime}
        setStartTime={setStartTime}
        endTime={endTime}
        setEndTime={setEndTime}
      />

      {/* Dialog for viewing event Details*/}
      {selectedEvent && (
        <EventDetailsDialog
          selectedEvent={selectedEvent}
          setSelectedEvent={handleClosEventSummaryDialog}
          hasAbilityToEdit={true}
          projectPage={true}
          handleDeleteEvent={handleDeleteEventRequest}
          handleEditEvent={handleEditEvent}
        />
      )}

      {/* ConfirmationDialog for deleting event */}
      <ConfirmationDialog
        isOpen={isConfirmationDialogOpen}
        onClose={() => setIsConfirmationDialogOpen(false)}
        onConfirm={deleteEvent} // Call the delete event handler on confirm
        title="Delete Event"
        message={`Are you sure you want to delete the event '${eventToDelete?.title}'?`}
      />
    </Box>
  );
};

export default Calendar;
