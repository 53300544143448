// EventDialog.js
import React from "react";
import { Dialog, DialogContent, DialogActions, TextField, Button, FormControlLabel, Switch, Checkbox, FormControl, Select, MenuItem, Box } from "@mui/material";

const EventDialog = ({
  isOpen,
  handleClose,
  handleSubmit,
  eventTitle,
  setEventTitle,
  eventStartTime,
  setEventStartTime,
  eventEndTime,
  setEventEndTime,
  showTimeInputs,
  setShowTimeInputs,
  shareWithClient,
  setShareWithClient,
  eventDescription,
  setEventDescription,
  eventBackgroundColor,
  setEventBackgroundColor,
  startTime,
  setStartTime,
  endTime,
  setEndTime,
}) => {
  const colorOptions = [
    { label: "DefaultBlue", value: "#3788d8" },
    { label: "yellow", value: "#ffD700" },
    { label: "Sage", value: "#FFB347" },
    { label: "Grape", value: "#54E9B7" },
    { label: "Flamingo", value: "#B1F46D" },
    { label: "Banana", value: "#6B936B" },
    { label: "Tangerine", value: "#3788D8" },
    { label: "Peacock", value: "#4B447E" },
    { label: "Graphite", value: "#C56BD1" },
    { label: "Blueberry", value: "#EC8178" },
    { label: "Basil", value: "#C71585" },
  ];

  const handleSubmitWithValidation = () => {
    // Parse dates and times
    const startDateTime = new Date(`${eventStartTime}T${showTimeInputs ? startTime : '00:00'}`);
    const endDateTime = new Date(`${eventEndTime}T${showTimeInputs ? endTime : '23:59'}`);
    
    // Check if start date/time is before or equal to end date/time
    if (startDateTime > endDateTime) {
      alert('The event start time must be before or equal to the end time.');
      return; // Prevent submission
    }
    
    // If validation passes, call the original handleSubmit function
    handleSubmit();
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth={true} maxWidth="sm">
      <DialogContent>
        <TextField
          margin="dense"
          label="Start Date"
          type="date"
          fullWidth
          value={eventStartTime}
          onChange={(e) => setEventStartTime(e.target.value)}
          sx={{ input: { fontSize: "16px" } }}
        />

        <TextField
          margin="dense"
          label="End Date"
          type="date"
          fullWidth
          value={eventEndTime}
          onChange={(e) => setEventEndTime(e.target.value)}
          sx={{ input: { fontSize: "16px" } }}
        />

        <TextField
          autoFocus
          margin="dense"
          label="Event Title"
          type="text"
          fullWidth
          value={eventTitle}
          onChange={(e) => setEventTitle(e.target.value)}
          sx={{ input: { fontSize: "16px" } }}
        />

        <TextField
          autoFocus
          margin="dense"
          label="Description"
          type="text"
          fullWidth
          value={eventDescription}
          onChange={(e) => setEventDescription(e.target.value)}
          sx={{ input: { fontSize: "16px" } }}
          multiline
          maxRows={4}
          inputProps={{ maxLength: 250 }}
        />

        {showTimeInputs && (
          <>
            <TextField
              margin="dense"
              label="Start Time"
              type="time"
              fullWidth
              value={startTime}
              onChange={(e) => setStartTime(e.target.value)}
              sx={{ input: { fontSize: "16px" } }}
            />
            <TextField
              margin="dense"
              label="End Time"
              type="time"
              fullWidth
              value={endTime}
              onChange={(e) => setEndTime(e.target.value)}
              sx={{ input: { fontSize: "16px" } }}
            />
          </>
        )}

        <FormControlLabel
          control={
            <Checkbox
              checked={shareWithClient}
              onChange={(e) => setShareWithClient(e.target.checked)}
              name="myCheckbox"
              color="primary"
            />
          }
          label="Share event with client"
        />

        <FormControlLabel
          control={
            <Switch
              checked={showTimeInputs}
              onChange={() => setShowTimeInputs(!showTimeInputs)}
            />
          }
          label="Add Time"
        />

        <FormControl sx={{ minWidth: 70, margin: "dense" }}>
          <Select
            value={eventBackgroundColor}
            onChange={(e) => setEventBackgroundColor(e.target.value)}
            sx={{ fontSize: "16px" }}
          >
            {colorOptions.map((color) => (
              <MenuItem key={color.value} value={color.value}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: color.value,
                      borderRadius: "50%",
                      width: 20,
                      height: 20,
                      marginRight: 1,
                    }}
                  />
                </Box>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmitWithValidation}>Save Event</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EventDialog;
