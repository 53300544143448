// calendarUtil.js
import axios from 'axios';
import Cookies from 'js-cookie';
import PersonIcon from '@mui/icons-material/Person';

const API_URL = process.env.REACT_APP_API_URL;

export const formatTime = (dateString) => {
    const date = new Date(dateString);
    const hours = date.getUTCHours().toString().padStart(2, "0"); // Convert to 24-hour format and pad with leading zero if needed
    const minutes = date.getUTCMinutes().toString().padStart(2, "0");
    return `${hours}:${minutes}`; // Return in HH:mm format
};

export const getCurrentTime = (offsetHours = 0) => {
    const now = new Date();
    if(now.getHours > now.getHours+offsetHours){
      now.setHours(23);
        now.setMinutes(59);
    } else {
      now.setHours(now.getHours() + offsetHours); // Add the offset to the current hours
    }
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    return `${hours}:${minutes}`;
};

export const executeDeleteEvent = async (event, calendarRef, setSelectedEvent, handleError) => {
    try {
        // Ensure they have write access
        const response = await axios.delete(
            `${API_URL}/calander-event/delete/${event.eventId}`,
            {
                withCredentials: true,
                withXSRFToken: true,
            }
        );

        setSelectedEvent(null);

        const calendarApi = calendarRef.current.getApi(); 
        const calendarEvent = calendarApi.getEventById(event.eventId); 

        if (calendarEvent) {
            calendarEvent.remove(); 
        }
    } catch (error) {
        handleError(error);
    }
};

export const saveEditedEvent = async (
    event,
    eventData,
    calendarRef,
    setIsEventDialogOpen,
    setEventToEdit,
    handleError
) => {
    try {
        const response = await axios.put(
            `${API_URL}/calander-event/edit`,
            eventData,
            {
                withCredentials: true,
                withXSRFToken: true,
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );

        const calendarApi = calendarRef.current.getApi();
        const calendarEvent = calendarApi.getEventById(eventData.eventId);

        if (calendarEvent) {
            calendarEvent.remove(); // Remove the existing event

            calendarApi.addEvent({
                id: response.data.eventId,
                title: response.data.title,
                start: response.data.start,
                end: response.data.end,
                allDay: response.data.allDay,
                backgroundColor: response.data.colourCode,
                icon: response.data.sharedWithClient ? <PersonIcon /> : "",
                extendedProps: {
                    shareWithClient: response.data.sharedWithClient,
                    description: response.data.description,
                    projectId: response.data.projectId,
                    employeeId: response.data.employeeId,
                    businessId: response.data.businessId,
                },
            });
        }

        setIsEventDialogOpen(false);
        setEventToEdit(null);
    } catch (error) {
        handleError(error);
    }
};

export const handleError1 = (error) => {
    if (error.response && error.response.status === 403) {
        alert("You are not authorized to perform this action. Please contact Admin for help.");
    } else {
        console.error("An error occurred:", error);
    }
};

export const formatDateToISO = (dateStr) => {
    return `${dateStr}T12:00:00Z`; // Append 'T00:00:00Z' to make it an ISO 8601 format
};

export const formatEndDateToISO = (dateStr) => {
    const date = new Date(dateStr);
    date.setDate(date.getDate() + 1); // Add one day to the date
    return `${date.toISOString().split('T')[0]}T12:00:00Z`; // Convert to ISO format and adjust time
};

// used to add events to the FullCalendar
export const addEventsToCalendar = (calendarRef, eventDTOs) => {
    const calendarApi = calendarRef.current.getApi(); // Access the calendar API
    calendarApi.removeAllEvents(); // Clear existing events
    console.log(
      "Current FullCalendar timeZone:",
      calendarRef.current.getApi().getOption("timeZone")
    );

    eventDTOs.forEach((eventDTO) => {
      calendarApi.addEvent({
        id: eventDTO.eventId,
        title: eventDTO.title,
        start: eventDTO.start,
        end: eventDTO.end,
        allDay: eventDTO.allDay,
        backgroundColor: eventDTO.colourCode || '#3788d8', // Set background color
        borderColor: eventDTO.colourCode || '#3788d8',
        icon:  eventDTO.sharedWithClient ? <PersonIcon />: "",
        extendedProps: {
          projectId: eventDTO.projectId,
          description:eventDTO.description,
          shareWithClient: eventDTO.sharedWithClient,
          employeeId: eventDTO.employeeId,
          businessId:eventDTO.businessId,
        },
      });

    });
  };

  // used to format the events being rendered on calander 
  export const renderEventContent = (eventInfo) => {
    // use when conditionally rendering the colour circle for non-full day events
    const isSameDay = (startStr, endStr) => {
      const startDate = new Date(startStr);
      const endDate = new Date(endStr);
      return (
        startDate.getUTCFullYear() === endDate.getUTCFullYear() &&
        startDate.getUTCMonth() === endDate.getUTCMonth() &&
        startDate.getUTCDate() === endDate.getUTCDate()
      );
    };

    return (
      <div 
        className="custom-event" 
        title={eventInfo.event.title}
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '16px',
        }}
      >
        {/* Only display the circle for non full day events */}
        {!eventInfo.event.allDay  && isSameDay(eventInfo.event.startStr, eventInfo.event.endStr) &&  (
          <span 
            style={{
              display: 'inline-block',
              width: '10px',
              height: '10px',
              borderRadius: '50%',
              backgroundColor: eventInfo.event.backgroundColor,
              marginRight: '5px',
            }}
          >
          </span>
        )}

        {eventInfo.event.extendedProps.icon && (
          <span style={{ marginTop: '5px' }}>
            {eventInfo.event.extendedProps.icon}
          </span>
        )}
        <strong style={{ marginLeft:'5px', marginRight: '5px' }}>{eventInfo.timeText}</strong>
        <span>{eventInfo.event.title}</span>
      </div>
    );
  };

  export const adjustEndDateForDisplay = (startStr, endStr, allDay) => {
    const startDate = new Date(startStr);
    const endDate = new Date(endStr);

    if (allDay) {
      // Subtract one day from the end date
      endDate.setDate(endDate.getDate() - 1);
    } else {
    }

    return endDate.toISOString().split("T")[0];
  };