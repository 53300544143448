import React, { useState, useEffect, useInsertionEffect } from "react";
import {useRef } from "react";
import { Box, Typography, Card, Grid, useTheme, Button } from "@mui/material";
import { tokens } from "../../theme";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import listPlugin from "@fullcalendar/list";
import FullCalendar, { formatDate } from "@fullcalendar/react";
import MessageIcon from "@mui/icons-material/Message";
import HandymanIcon from "@mui/icons-material/Handyman";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

import GroupAddIcon from "@mui/icons-material/GroupAdd";
import GroupRemoveIcon from "@mui/icons-material/GroupRemove";
import Cookies from "js-cookie";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import EventDetailsDialog from "./EventDetailsDialog";
import { addEventsToCalendar, renderEventContent } from '../calendar/calendarUtil';


const NotificationItem = ({ icon, description, time }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Convert Unix timestamp to a readable date format
  const date = new Date(time * 1000); // Multiply by 1000 to convert seconds to milliseconds
  const formattedDateTime = date.toLocaleString([], {
    year: "numeric",
    month: "short",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        borderBottom: "1px solid #ddd",
        py: theme.spacing(1),
        "&:hover": {
          backgroundColor: colors.grey[700],
          boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)", // This creates the glow effect
          cursor: "pointer",
        },
      }}
    >
      {/* Icon on the left */}
      <Box sx={{ marginRight: theme.spacing(1), color: colors.MoonBlue[100] }}>
        {icon}
      </Box>

      {/* Description */}
      <Typography
        fontWeight="600"
        variant="h5"
        sx={{ color: colors.MoonBlue[100] }}
      >
        {description}
      </Typography>

      {/* Displaying the formatted date */}
      <Typography sx={{ marginLeft: "auto", color: colors.MoonBlue[100] }}>
        {formattedDateTime}
      </Typography>
    </Box>
  );
};

const ScheduleItem = ({ title, start, allDay, onClick }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const formatDate = (isoDateString) => {
    const date = new Date(isoDateString);
    return date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
      timeZone: "UTC",
    });
  };

  const displayTime = allDay ? "All Day" : formatDate(start);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid #ddd",
        py: theme.spacing(1),
        minHeight: "43.13px",
        cursor: "pointer", // Make it obvious it's clickable
        "&:hover": {
          backgroundColor: "rgba(0, 0, 0, 0.04)", // Optional: visual feedback on hover
        },
      }}
      onClick={onClick} // Use the onClick prop
    >
      <Box>
        <Typography
          variant="h5"
          fontWeight="600"
          sx={{ color: colors.primary[100] }}
        >
          {title}
        </Typography>
      </Box>
      <Box sx={{ color: colors.primary[500] }}>{displayTime}</Box>
    </Box>
  );
};

const BuilderDashboard = () => {
  const theme = useTheme();
  const navigate = useNavigate(); // Initialize the navigate function
  const colors = tokens(theme.palette.mode);
  const [loading, setLoading] = useState(true);
  const businessId = Cookies.get("businessId");
  const API_URL = process.env.REACT_APP_API_URL;
  const [notifications, setNotifications] = useState([]);
  const [error, setError] = useState(null);
  const [events, setEvents] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const userId = Cookies.get("userId");
  const [users, setUsers] = useState([]);

  const [selectedEvent, setSelectedEvent] = useState(null);

  //logic for pages for notifications
  const [currentPageNotification, setCurrentPageNotification] = useState(1);

  const [currentPageEvent, setCurrentPageEvent] = useState(1);

  const notificationsPerPage = 7;
  const CalanderEventsPerPage = 7;

  const indexOfLastNotification =
    currentPageNotification * notificationsPerPage;
  const indexOfFirstNotification =
    indexOfLastNotification - notificationsPerPage;

  const indexOfLastEvent = currentPageEvent * CalanderEventsPerPage;
  const indexOfFirstEvent = indexOfLastEvent - CalanderEventsPerPage;

  // ref to store the calendar instance
  const calendarRef = useRef(null);

  const nextPageNotification = () => {
    setCurrentPageNotification((prevPageNumber) => prevPageNumber + 1);
  };

  const prevPageNotification = () => {
    setCurrentPageNotification((prevPageNumber) => prevPageNumber - 1);
  };

  const nextPageEvent = () => {
    setCurrentPageEvent((prevPageNumber) => prevPageNumber + 1);
  };

  const prevPageEvent = () => {
    setCurrentPageEvent((prevPageNumber) => prevPageNumber - 1);
  };

  const totalPagesNotifications = Math.ceil(
    notifications.length / notificationsPerPage
  );

  const totalPagesEvent = Math.ceil(events.length / CalanderEventsPerPage);

  // navigation to specific notification dependent on notification type
  const handleNotificationClick = (eventType, clientUserId, projectId) => {
    switch (eventType) {
      case "EVENT_TYPE_7":
        navigate(`/users/${clientUserId}/${projectId}/message`);
        break;
      case "EVENT_TYPE_8":
        navigate(`/users/${clientUserId}/${projectId}/finishings`);
        break;
      case "EVENT_TYPE_9":
        navigate(`/users/${clientUserId}/${projectId}/todos`);
        break;
      case "EVENT_TYPE_10":
        navigate(`/users/${clientUserId}/${projectId}/document`);
        break;
      case "EVENT_TYPE_13":
        navigate(`/users/${clientUserId}/${projectId}/finishings`);
        break;
      case "EVENT_TYPE_14":
        navigate(`/users/${clientUserId}/${projectId}`);
        break;
      case "EVENT_TYPE_15":
        navigate(`/users/${clientUserId}/${projectId}`);
        break;
      default:
        navigate(`/users/${clientUserId}/${projectId}`);
        break;
    }
  };

  // Get Notification Info excluding EVENT_TYPE_18
  useEffect(() => {
    async function fetchNotificationData() {
      try {
        const response = await fetch(
          `${API_URL}/notification/get-notifications-for-user/${userId}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              Accept: "application/json",
            },
          }
        );

        if (!response.ok) {
          throw new Error(`Network response was not ok ${response.statusText}`);
        }

        const data = await response.json();
        // Filter out EVENT_TYPE_18 notifications
        const filteredNotifications = data.filter(
          (notification) => notification.eventType !== "EVENT_TYPE_18"
        );
        setNotifications(filteredNotifications);
        setLoading(false);
      } catch (err) {
        console.error(`Error fetching data for business `, err);
        setLoading(false);
      }
    }
    fetchNotificationData();
  }, []);

  useEffect(() => {
    fetchEvents();
    fetchAllEvents();
  }, []);

  const getStartAndEndOfCurrentDayLocal = () => {
    const now = new Date();
    const startOfDay = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate()
    );
    const endOfDay = new Date(startOfDay.getTime() + 86400000 - 1); // Add one day in milliseconds and subtract 1 millisecond

    return {
      start: startOfDay.toISOString(),
      end: endOfDay.toISOString(),
    };
  };

  const fetchEvents = async () => {
    try {
      const { start, end } = getStartAndEndOfCurrentDayLocal();

      const url = `${API_URL}/calander-event/getEventsForDay`;
      const params = {
        userId: Cookies.get("userId"),
        start: start,
        end: end,
      };

      const response = await axios.get(url, { params, withCredentials: true });
      const eventDTOs = response.data;

      setEvents(eventDTOs);
    } catch (error) {
      console.error("Error fetching events:", error);
      // Handle error appropriately
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {
        // Then use it in your fetch call:
        const response = await fetch(
          `${API_URL}/client/get-users?userId=${userId}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              Accept: "application/json",
              //'X-XSRF-TOKEN': cookie
            },
          }
        );

        const data = await response.json();
        setUsers(data);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    }

    fetchData();
  }, [userId]);

  const fetchAllEvents = async () => {
    try {
      const response = await axios.get(
        `${API_URL}/calander-event/get-event-for-userId/${Cookies.get(
          "userId"
        )}`,
        { withCredentials: true }
      );
      const eventDTOs = response.data;

      //setAllEvents(eventDTOs);
      addEventsToCalendar( calendarRef, eventDTOs);
    } catch (error) {
      console.error("Error fetching events:", error);
      // Handle error appropriately
    }
  };

  const handleEventClick = (clickInfo) => {
    setSelectedEvent({
      title: clickInfo.event.title,
      start: clickInfo.event.startStr,
      end: clickInfo.event.endStr,
      allDay: clickInfo.event.allDay,
      projectId: clickInfo.event.extendedProps.projectId,
    });
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  };

  const handleScheduleItemClick = (eventDetails) => {
    setSelectedEvent(eventDetails);
  };
  const formatTime = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
  };

  const renderNotification = (notification) => {
    let message;
    let icon;
    switch (notification.eventType) {
      case "EVENT_TYPE_7":
        message = `${notification.senderFirstName} ${notification.senderLastName} sent a message`;
        icon = <MessageIcon />;
        break;
      case "EVENT_TYPE_8":
        message = `${notification.senderFirstName} ${notification.senderLastName} confirmed a selection`;
        icon = <HandymanIcon />;
        break;
      case "EVENT_TYPE_9":
        message = `${notification.senderFirstName} ${notification.senderLastName} Completed a task`;
        icon = <FormatListBulletedIcon />;
        break;
      case "EVENT_TYPE_10":
        message = `${notification.senderFirstName} ${notification.senderLastName} added a new document`;
        icon = <DocumentScannerIcon />;
        break;
      case "EVENT_TYPE_13":
        message = `${notification.senderFirstName} ${notification.senderLastName} made a selection request`;
        icon = <HandymanIcon />;
        break;
      case "EVENT_TYPE_14":
        message = `${notification.senderFirstName} ${notification.senderLastName} added you to the project ${notification.projectName}`;
        icon = <GroupAddIcon />;
        break;
      case "EVENT_TYPE_15":
        message = `${notification.senderFirstName} ${notification.senderLastName} removed you from the project ${notification.projectName}`;
        icon = <GroupRemoveIcon />;
        break;
      case "EVENT_TYPE_18": //skip internal messages
        return null;

      default:
        message = `Notification received from ${notification.senderFirstName} ${notification.senderLastName}`;
        icon = <NotificationsIcon />;
    }

    return (
      <div
        onClick={() =>
          handleNotificationClick(
            notification.eventType,
            notification.senderUserId,
            notification.projectId
          )
        }
        key={notification.notificationId}
      >
        <NotificationItem
          icon={icon}
          description={message}
          time={notification.timeStamp}
        />
      </div>
    );
  };

  const handleCloseDialog = () => {
    setSelectedEvent(null);
  };

  if (loading) {
    return <div>Loading...</div>;
  }
  return (
    <Box
      sx={{
        backgroundColor: colors.white[500],
        borderRadius: "5px",
        padding: theme.spacing(3),
        pt: 2,
        pb: 0,
        boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
        minHeight: "100%",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          color: colors.MoonBlue[100],
          fontWeight: "600",
          mb: theme.spacing(6),
        }}
      >
        WELCOME TO YOUR DASHBOARD
      </Typography>
      <Grid container spacing={3}>
        <Grid xs={12} md={6}>
          <Card
            sx={{
              backgroundColor: colors.white[500],
              borderRadius: "10px",
              boxShadow: "0 0 10px rgba(3, 3, 5, 0.3)",
              p: theme.spacing(3),
              ml: 3,
              mb: 2,
              minHeight: "415px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center", // Align items vertically in the center
                justifyContent: "center", // Center the items horizontally
                marginBottom: theme.spacing(2), // Add some margin at the bottom
              }}
            >
              <NotificationsIcon
                sx={{
                  width: 25,
                  height: 25,
                  marginRight: theme.spacing(1), // Add some margin to the right of the icon
                  color: colors.MoonBlue[100],
                }}
              />
              <Typography
                variant="h7"
                sx={{
                  fontSize: 20,
                  fontWeight: "600",
                  color: colors.MoonBlue[100],
                }}
              >
                Notifications
              </Typography>
            </Box>
            {notifications.length > 0 ? (
              notifications
                .slice() // Create a shallow copy to avoid mutating the original array
                .sort((a, b) => b.timeStamp - a.timeStamp) // Sort based on timestamp (newest first)
                .slice(indexOfFirstNotification, indexOfLastNotification) // Slice for pagination
                .map(renderNotification)
            ) : (
              <div>No notifications</div>
            )}

            {/* Pagination Controls, displayed only if there's more than one page */}
            {totalPagesNotifications > 1 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "auto",
                  color: colors.MoonBlue[100],
                }}
              >
                <button
                  onClick={prevPageNotification}
                  disabled={currentPageNotification === 1}
                  sx={{
                    color: colors.MoonBlue[100],
                  }}
                >
                  Previous
                </button>

                <Typography
                  sx={{
                    flexGrow: 1,
                    textAlign: "center",
                    color: colors.MoonBlue[100],
                  }}
                >
                  Page {currentPageNotification} / {totalPagesNotifications}
                </Typography>

                <button
                  onClick={nextPageNotification}
                  disabled={currentPageNotification >= totalPagesNotifications}
                  sx={{
                    color: colors.MoonBlue[100],
                  }}
                >
                  Next
                </button>
              </div>
            )}
          </Card>
        </Grid>

        <Grid xs={12} md={6}>
          <Card
            sx={{
              backgroundColor: colors.white[500],
              borderRadius: "10px",
              boxShadow: "0 0 10px rgba(3, 3, 5, 0.3)",
              p: theme.spacing(3),
              ml: 3,
              mb: 2,
              minHeight: "415px",
            }}
          >
            <Box
              sx={{
                display: "flex", // Use flex display
                alignItems: "center", // Align items vertically in the center
                justifyContent: "center", // Center the items horizontally
                marginBottom: theme.spacing(2), // Add some margin at the bottom
              }}
            >
              <AccessTimeIcon
                sx={{
                  width: 25,
                  height: 25,
                  marginRight: theme.spacing(1), // Add some margin to the right of the icon
                  color: colors.MoonBlue[100],
                }}
              />
              <Typography
                variant="h7"
                sx={{
                  fontSize: 20,
                  fontWeight: "600",
                  color: colors.MoonBlue[100],
                }}
              >
                Today's Schedule
              </Typography>
            </Box>
            {events
              .slice()
              .sort((a, b) => {
                // First, sort based on allDay flag
                if (a.allDay && !b.allDay) return 1;
                if (!a.allDay && b.allDay) return -1;

                // Then, sort by start time
                return new Date(a.start) - new Date(b.start);
              })
              .slice(indexOfFirstEvent, indexOfLastEvent)

              .map((event) => (
                <ScheduleItem
                  key={event.eventId}
                  title={event.title}
                  start={event.start}
                  allDay={event.allDay}
                  onClick={() =>
                    handleScheduleItemClick({
                      title: event.title,
                      start: event.start,
                      end: event.end, // Assuming you have an 'end' in your event object
                      allDay: event.allDay,
                      projectId: event.projectId,
                    })
                  }
                />
              ))}

            {/* Pagination Controls, displayed only if there's more than one page */}
            {totalPagesEvent > 1 && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginTop: "auto",
                }}
              >
                <button
                  onClick={prevPageEvent}
                  disabled={currentPageEvent === 1}
                  sx={{ color: colors.MoonBlue[100] }}
                >
                  Previous
                </button>

                <Typography
                  sx={{
                    flexGrow: 1,
                    textAlign: "center",
                    color: colors.MoonBlue[100],
                  }}
                >
                  Page {currentPageEvent} / {totalPagesEvent}
                </Typography>

                <button
                  onClick={nextPageEvent}
                  disabled={currentPageEvent >= totalPagesEvent}
                  sx={{ color: colors.MoonBlue[100] }}
                >
                  Next
                </button>
              </div>
            )}
          </Card>
        </Grid>

        <Grid xs={12} md={12}>
          <Card
            sx={{
              backgroundColor: colors.white[500],
              borderRadius: "10px",
              boxShadow: "0 0 10px rgba(3, 3, 5, 0.3)",
              ml: 3,
              mb: 2,
              p: theme.spacing(3), // Apply padding here if needed
            }}
          >
            <FullCalendar
              ref={calendarRef}
              plugins={[dayGridPlugin, interactionPlugin, listPlugin]}
              timeZone="utc"
              initialView="dayGridWeek"
              headerToolbar={false}
              footerToolbar={false}
              editable={false}
              //events={allEvents}
              eventContent={renderEventContent}
              dayMaxEvents={2}
              eventClick={handleEventClick}
              dayHeaderContent={(headerInfo) => {
                const weekday = headerInfo.date.toLocaleString("en-US", {
                  weekday: "short",
                }); // "Mon", "Tue", etc.
                const date = headerInfo.date.toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                }); // "Dec 29"

                return (
                  <div>
                    <div>{weekday}</div>
                    <div>{date}</div>
                  </div>
                );
              }}
            />
          </Card>
        </Grid>
      </Grid>

      {selectedEvent && (
        <EventDetailsDialog
          selectedEvent={selectedEvent}
          setSelectedEvent={handleCloseDialog}
          // clientUserId = clientUserId
        />
      )}
    </Box>
  );
};

export default BuilderDashboard;
