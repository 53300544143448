import React, { useState, useEffect, useInsertionEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  Button,
  Box,
  useTheme,
  Menu,
  MenuItem,
  IconButton
} from "@mui/material";
import { tokens } from "../../theme";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import MoreVertIcon from '@mui/icons-material/MoreVert';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PersonIcon from '@mui/icons-material/Person';

import ACCESS_CATEGORIES from "../../constants/AccessCatagories"
import checkWriteAccess from "../../util/CheckWriteAccess";


//todo: move this to a location that makes more sense. This is being used by more then just the calender preview in the dashboard

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getUTCFullYear();
  const month = date.toLocaleString("en-US", {
    month: "long",
    timeZone: "UTC",
  });
  const day = date.getUTCDate().toString().padStart(2, "0");
  return `${month} ${day}, ${year}`;
};

const formatTime = (dateString) => {
  const date = new Date(dateString);
  let hours = date.getUTCHours();
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  return `${hours}:${minutes} ${ampm}`;
};

const EventDetailsDialog = ({
  selectedEvent,
  setSelectedEvent,
  handleDeleteEvent,
  handleEditEvent,
  projectId,
  clientUserId,
  hasAbilityToEdit=false,
  projectPage=false
}) => {
  const allDay = selectedEvent?.allDay;
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [error, setError] = useState(null);
  const [user, setUser] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null); // For menu anchor
  const open = Boolean(anchorEl);



  const API_URL = process.env.REACT_APP_API_URL;
  //Get Project Info
  useEffect(() => {
    async function fetchProjectData() {
      if (!selectedEvent || !selectedEvent.projectId) return;


      try {
        const response = await fetch(
          `${API_URL}/project/get-single-project-for-client?projectId=${selectedEvent.projectId}`,
          {
            method: "GET",
            credentials: "include",
            headers: {
              Accept: "application/json",
              //'X-XSRF-TOKEN': cookie
            },
          }
        );

        const data = await response.json();
        setProject(data);

        setLoading(false);
      } catch (err) {
        console.error(
          `Error fetching data for userId ${selectedEvent.projectId}:`,
          err
        );
        setError(err);
        setLoading(false);
      }
    }
    fetchProjectData();
  }, [selectedEvent?.projectId]);

  const fetchData = async () => {
    try {
      const response = await fetch(
        `${API_URL}/client/get-single-user?userId=${project.clientId}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            Accept: "application/json",
            //'X-XSRF-TOKEN': cookie
          },
        }
      );

      const data = await response.json();
      setUser(data);
    } catch (error) {
      console.error("Error fetching user data:", error);
    }
  };

  // this is need because when events are label as 'allDay' where is one day or 'n'-fulldays, then end date associated with the event is always the day after the end date selected
  // this is just a quarq of the react full-calender were using
  // hence why use need a method like this before displaying the end date or doing comparisons with the end date
  const adjustEndDateForAllDayEvent = (dateString) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() - 1);
    return date.toISOString();
  };
  

  useEffect(() => {
    if (project?.clientId) {
      fetchData();
    }
  }, [project?.clientId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error fetching data!</div>;
  }

  return (
    <Dialog
      open={Boolean(selectedEvent)}
      onClose={() => setSelectedEvent(null)}
      aria-labelledby="event-details-title"
      sx={{
        "& .MuiDialog-container .MuiPaper-root": {
          borderRadius: 2,
          padding: 2,
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          position: 'relative',
          width: '100%',
          verflow: 'hidden'
        }}
      >
        <DialogTitle
          id="event-details-title"
          sx={{ 
            fontWeight: 'bold', 
            flexGrow: 1,
            textAlign: 'center',
            marginRight: 'auto',
            whiteSpace: 'normal', 
            wordBreak: 'break-word', 
            overflowWrap: 'break-word', 
            maxWidth: 'calc(100% - 48px)', 
          }}
        >
          {selectedEvent?.title}
        </DialogTitle>
        { hasAbilityToEdit && (
        <>
        <IconButton
          onClick={(event) => { setAnchorEl(event.currentTarget); }}
          sx={{ position: 'absolute', right: 0 }}
        >
          <MoreVertIcon />
        </IconButton>

        <Menu anchorEl={anchorEl} open={open} onClose={() => setAnchorEl(null)}>
          <MenuItem onClick={() => {handleDeleteEvent(selectedEvent); setAnchorEl(null);}}>
            <DeleteIcon fontSize="small" sx={{ mr: 1 }} /> Delete
          </MenuItem>
          <MenuItem onClick={() => {handleEditEvent(selectedEvent); setAnchorEl(null);}}>
            <EditIcon fontSize="small" sx={{ mr: 1 }} /> Edit
          </MenuItem>
        </Menu>
        </>
        )}
      </Box>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
        }}
      >
        <Typography sx={{ color: "#333", fontWeight: "medium" }}>
          <strong>Date:</strong>{" "}
          {allDay ? (
            selectedEvent?.start && selectedEvent?.end ? (
              formatDate(selectedEvent.start) === formatDate(adjustEndDateForAllDayEvent(selectedEvent.end)) ? (
                formatDate(selectedEvent.start)
              ) : (
                formatDate(selectedEvent.start) + " - " + formatDate(adjustEndDateForAllDayEvent(selectedEvent.end))
              )
            ) : ""
          ):(
              selectedEvent?.start && selectedEvent?.end ? (
                formatDate(selectedEvent.start) === formatDate(selectedEvent.end) ? formatDate(selectedEvent.start) : formatDate(selectedEvent.start) + ' - ' + formatDate(selectedEvent.end) 
              ) : ""
          )}
          
        </Typography>

        {allDay ? (
            ""
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 1,
            }}
          >
            <Typography sx={{ color: "#333", fontWeight: "medium" }}>
              <strong>Start:</strong>{" "}
              {selectedEvent?.start ? formatTime(selectedEvent.start) : ""}
            </Typography>
            <Typography sx={{ color: "#333", fontWeight: "medium" }}>
              <strong>End:</strong>{" "}
              {selectedEvent?.end ? formatTime(selectedEvent.end) : ""}
            </Typography>
          </Box>
        )}

        <Typography sx={{ color: "#333", fontWeight: "medium" }}>
          <strong>Address:</strong> {project?.address || "N/A"}
        </Typography>
        <Typography sx={{ color: "#333", fontWeight: "medium" }}>
          <strong>Client:</strong>{" "}
          {`${user?.firstName || "N/A"} ${user?.lastName || ""}`.trim()}
        </Typography>

        <Typography sx={{ color: "#333", fontWeight: "medium" }}>
          <strong> Description:</strong>{" "}
          {selectedEvent.description}
        </Typography>

        {selectedEvent.shareWithClient && (
          <Box display="flex" alignItems="center">
          <PersonIcon style={{ color: '#28a745', marginRight:'5px' }} />
            <Typography sx={{ color: "#333", fontWeight: "medium" }}>
              <strong>Shared With Client</strong>{" "}
            </Typography>
          </Box>
        )}
        
        {!projectPage && <Button
          sx={{
            marginTop: 2,
            alignSelf: "center",
            backgroundColor: colors.MoonBlue[100],
            color: colors.white[500],
            ":hover": {
              backgroundColor: "#115293",
            },
            fontWeight: "bold",
          }}
          onClick={() =>
            navigate(
              `/users/${project?.clientId}/${selectedEvent.projectId}/calendar`
            )
          }
        >
          View Project Calendar
        </Button>}
      </DialogContent>
    </Dialog>
  );
};

export default EventDetailsDialog;
